import React, { memo } from "react";
import $ from 'jquery';
import {Link} from "react-router-dom";
import {setPref} from "./reducer/userPref";
import {connect} from "react-redux";
import {setNetworkCount} from "./reducer/network";
import {authService} from "./auth";
import axios from "axios";
import * as _ from "lodash";
import ProfileOrder from "./ProfileOrder";
import {setMessageCount, setMessageCountOnSuccess} from "./reducer/message";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { setWpondYearData } from "./reducer/wpond/WpondReducer";
import PostSectionPopUp from "./PostSectionPopUp";
import ProfileCompletionPopup from "./ProfileCompletionPopup";
class LeftPanel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pollCount: this.props.pollCount,
            newUserChatCnt: this.props.newUserChatCnt,
            userForumCount:0,
            userPollCount:0,
            projectCount: this.props.projectCount,
            networkCount: this.props.networkCount,
            userData: authService.getUserDetailsFromLocalStorage(),
            loginSuccess: authService.isAuthenticated(),
            year: "",
            adminAccess: false
        };

    }

    getAdminAccessDetails = () => {
        axios({
            method: "GET",
            url: `${process.env.REACT_APP_userServiceURL}/adminPanelUser/getPermission?userId=${this.state.userData.userId}`,
            withCredentials: true,
        })
            .then((response) => {
                if (
                    response &&
                    response.data &&
                    response.data.body &&
                    response.data.message === "Success!"
                ) {
                    if(response.data.body) {
                        this.setState({
                            adminAccess: true
                        })
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }


    openModalDetailsApiCall = (modalType, modalId) => {
        this.setState({
            [modalType]: true
        },() => {
            window.$("#addpostpop").modal("hide");
            window.$("#"+modalId).modal("show");
        })
        
    }
    

    componentDidMount() {
        this.props.getPref();
        if (this.state.userData && this.state.userData.type === 'INDIVIDUAL') {
            this.props.getNetworkCount()
        }

        if (this.props.messageTab === undefined) {
            this.getAllUnReadMessageBYAllTab();
            //this.props.getMessageCount();
        }

        this.getAllForumCountTab()
        this.getAllPollCount();
        this.getAdminAccessDetails();
        this.props.setWpondYearData(true);
        authService.setGeoLocation();

       

        setTimeout(() => {
            window.$(".ltWPONDLogo").addClass('hide');
        }, 15000)
    }

    componentWillUnmount() {
        window.$("#AcceptPopUp").modal("hide");
        window.$("#addForum").modal("hide");
        clearInterval(this.interval);
        this.interval = null;
    }


    getAllUnReadMessageBYAllTab() {
        axios.get(process.env.REACT_APP_userServiceURL + '/messaging/message_service/api/message/getAllUnReadMessageBYAllTab?userId=' + this.state.userData.userId, {withCredentials: true}).then(response => {
            if (response && response.data.body !== null) {
                // console.log('messageJson getAllUnReadMessageBYAllTab');
                this.setState({newUserChatCnt: response.data.body})
            }
        });
    }

    // forum count display
    getAllForumCountTab = () =>{
        axios({
            method:'GET',
            url:`${process.env.REACT_APP_userServiceURL}/backend/public/forum/unseen/forum?userId=`+this.state.userData.userId,
            withCredentials: true
        }).then((response)=>{
            if(response && response.data.body !==  null){
                this.setState({
                    userForumCount:response.data.body
                })
            }
        }).catch((error)=>{
            console.log(error);
        })
    }


    // read all forum
    seenAllForum = () =>{
        axios({
            method:'POST',
            url:`${process.env.REACT_APP_userServiceURL}/backend/public/forum/seen/forum?userId=${this.state.userData.userId}`,
            withCredentials:true
        }).then((response)=>{
            this.getAllForumCountTab()
        }).catch((error)=>{
            console.log(error)
        })
    }

    //Readmore readless
    moreButton = () => {
        $('.more-menu').slideToggle();
        if ($('.read-more-less-btn').text() == "More...") {            
            $('.read-more-less-btn').empty();
            $('.read-more-less-btn').html('Less...').addClass("less");
        } else {
            $('.read-more-less-btn').empty();
            $('.read-more-less-btn').html('More...').removeClass("less");
        }
    }

    // poll count display
    getAllPollCount = () =>{
        axios({
            method:'GET',
            url:`${process.env.REACT_APP_userServiceURL}/backend/public/poll/active/count?userId=${this.state.userData.userId}`,
            withCredentials:true
        }).then((response)=>{
            if(response && response.data && response.data.body != null){
                this.setState({
                    userPollCount:response.data.body
                })
            }
        }).catch((error)=>{
            console.log(error)
        })
    }



    getAllPollUnvotedCount() {
        axios.get(process.env.REACT_APP_userServiceURL + '/public/poll/active/count?userId=' + this.state.userData.userId, {withCredentials: true}).then(response => {
            if (response && response.data.body !== null) {
                this.setState({pollCount: response.data.body})
            }
        });
    }

    componentWillReceiveProps(props) {
        if (props.projectCount !== undefined && this.state.projectCount !== props.projectCount) {
            this.setState({projectCount: props.projectCount});
        }
        if (props.pollCount !== undefined && this.state.pollCount !== props.pollCount) {
            this.setState({pollCount: props.pollCount});
        }
        if (props.networkCount !== undefined && this.state.networkCount !== props.networkCount) {
            this.setState({networkCount: props.networkCount});
        }

        if (props.newUserChatCnt !== undefined &&   this.state.newUserChatCnt !== props.newUserChatCnt) {
            this.setState({newUserChatCnt: props.newUserChatCnt});
            if(this.props.resetNewUserChatCnt){
                this.props.resetNewUserChatCnt();
            }
        }

        if (props.messageCount !== undefined &&  this.state.newUserChatCnt !== props.messageCount) {
            this.setState({newUserChatCnt: props.messageCount});
            this.props.setMessageCountOnSuccess();
        }



        if (props.messageReceivedResponse !== undefined
            && props.messageReceivedResponse.payload !== undefined
            && props.messageReceivedResponse.payload.body !== undefined
            && this.state.newUserChatCnt !== undefined
            && this.props.messageTab === undefined) {

            let messageJson = JSON.parse(props.messageReceivedResponse.payload.body);
            //console.log('messageJson: '+messageJson);
            //console.log('messageJson newUserChatCnt: '+this.state.newUserChatCnt);
            if (messageJson.deletedType === "All" || messageJson.messageType === "Acknowledge" || messageJson.senderId === this.state.userData.userId) {
            } else if (messageJson.senderId !== this.state.userData.userId && messageJson.messageGroupType !== 'NewsGroup' && !messageJson.privateEntityId) {
                this.setState({"newUserChatCnt": this.state.newUserChatCnt + 1});
                //this.props.getMessageCount();
            }
        }

        if(!_.isEmpty(this.props.yearData) && this.props.getYearData) {
            this.props.getYearData(this.props.yearData);
        }
    }

    render() {
        return (
            <>
                {/* <EndorsementPopUp/> */}

                <div className="col-md-3">
                    <div className="leftpanel">
                        <div className="sidemenu">
                            {/* <ul>
                                <li className="feeds">
                                    <NavLink exact to="/" activeClassName="active">
                                        Feeds <span className="new">new</span>
                                    </NavLink>
                                </li>
                                <li className="explore">
                                    <NavLink to="/explore" activeClassName="active"
                                             isActive={(_, {pathname}) => ["/explore", "/explore-curated", "/explore-trending","/explore-daily-edition","/explore-trending/community","/explore-trending/videos","/explore-trending/photos","/explore-trending/blogs"].includes(pathname)}>
                                        Explore
                                    </NavLink>
                                </li>
                                <li className="message">
                                    <NavLink to="/message" activeClassName="active">
                                        Message
                                        {this.state.newUserChatCnt && this.state.newUserChatCnt > 0 ?
                                            <span>{this.state.newUserChatCnt}</span> : ''}
                                    </NavLink>                                        
                                </li>                                
                                {this.state.loginSuccess && this.state.userData.type === 'INDIVIDUAL' &&
                                    <li className="network">
                                        <NavLink to="/network" activeClassName="active"
                                                 isActive={(_, {pathname}) => ["/network", "/popular-users", "/connect-requests", "/connected", "/following", "/pages", "/network-groups"].includes(pathname)}>
                                            Network
                                            {this.state.networkCount && this.state.networkCount > 0 ?
                                                <span>{this.state.networkCount}</span> : ''}
                                        </NavLink>
                                    </li>
                                }
                                <li className="circle">
                                    <NavLink to="/community" activeClassName="active">
                                        Circle
                                    </NavLink>
                                </li>
                                <li className="project">
                                    <NavLink to="/project" activeClassName="active">
                                        Projects
                                        {this.state.projectCount && this.state.projectCount > 0 ?
                                            <span>{this.state.projectCount}</span> : ''}
                                    </NavLink>
                                </li>
                                <li><a href="/#">Research</a></li>                                
                                <li className="causes">
                                    <NavLink to="/causes" activeClassName="active">
                                        Causes
                                    </NavLink>
                                </li>                                
                                <li className="forum">
                                    <NavLink to="/forum" activeClassName="active" onClick={(e)=>{this.seenAllForum()}}>
                                        Forum
                                        {this.state.userForumCount && this.state.userForumCount > 0 ? 
                                            <span>{this.state.userForumCount}</span> : ''
                                        }
                                    </NavLink>
                                </li>
                                <li className="poll">
                                    <NavLink to="/poll" activeClassName="active">
                                        Poll
                                        {this.state.userPollCount && this.state.userPollCount > 0 ?
                                            <span>{this.state.userPollCount}</span> : ''}
                                    </NavLink>
                                </li>
                            </ul> */}
                            
                            <ProfileOrder
                                projectCount={this.state.projectCount}
                                userPollCount={this.state.userPollCount}
                                networkCount={this.state.networkCount}
                                newUserChatCnt={this.state.newUserChatCnt}
                                userForumCount={this.state.userForumCount}
                                seenAllForum={this.seenAllForum}
                                communityActive={this.props.communityActive}
                            />

                            <div>
                                <a href="javascript:void(0)" className="btn read-more-less-btn" onClick={(e)=>{this.moreButton()}}>
                                    More...
                                </a>
                            </div>
                            
                            {/* <div className="ltpostbuttwrap">
                                <a href="#addpostpop" className="btn" data-toggle="modal">
                                    <i className="iAddPost"/>Post
                                </a>
                            </div> */}
                            {this.props.yearData && (this.props.yearData.status === "UPCOMING" ||  (this.props.yearData.status === "COMPLETED" && this.props.yearData.showWpondLogo)) && 
                                <div className="ltWPONDLogo">
                                    <Link className="dropdown-item" to={`/people-of-nature-award/${this.props.yearData.status === "UPCOMING" ? "upcoming" : "event"}/${this.props.yearData.year}`}>
                                        <LazyLoadImage
                                            effect="blur"
                                            src="https://cdn.dscovr.com/images/wikiPageAward-logo-new.png"
                                            onError={(e) => { e.target.onerror = null; e.target.src = "https://cdn.dscovr.com/images/wikiPageAward-logo-new.png" }}
                                            alt="" />
                                        <div>Awards {this.state.year} 
                                        <span>
                                            <span className="btn">Nominate</span></span>
                                            </div>
                                    </Link>
                                </div>
                            }
                            <div className="topDropdownMob">
                            {this.props.yearData && (this.props.yearData.status === "UPCOMING" ||  (this.props.yearData.status === "COMPLETED" && this.props.yearData.showInThreeDot)) && <Link className="dropdown-item" to={`/people-of-nature-award/${this.props.yearData.status === "UPCOMING" ? "upcoming" : "event"}/${this.props.yearData.year}`}>People of Nature Awards</Link>}
                                <Link className="dropdown-item" to="/wenaturalists-pro">WeNaturalists Pro</Link>
                                <Link className="dropdown-item" to="/dashboard-settings">Dashboard and Settings</Link>
                                {/* <div className="dropdown">
                                    <a href="javascript:void(0)" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Manage</a>
                                    <div className="dropdown-menu fadeIn animated" aria-labelledby="navbarDropdownMenuLink">                                        
                                        {this.state.loginSuccess && <Link className="dropdown-item" to="/manage-invitation">Manage Invitations</Link>}
                                        {this.state.loginSuccess && <Link className="dropdown-item" to={`/${this.state.userData.customUrl}/studio`}>Manage Studio</Link>}
                                        {this.state.loginSuccess && <Link className="dropdown-item" to="/forum-poll-draft">Drafts</Link>}
                                        <Link className="dropdown-item" to="/hidden-content">Hidden Content</Link>
                                        <Link className="dropdown-item" to="/analytics">Analytics</Link>
                                    </div>
                                </div> */}
                                <div className="dropdown">
                                    <a href="javascript:void(0)" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">About WeNaturalists</a>
                                    <div className="dropdown-menu fadeIn animated" aria-labelledby="navbarDropdownMenuLink">                                        
                                        <Link className="dropdown-item" to="/about-us">About Us</Link>
                                        <Link className="dropdown-item" to="/how-we-impacts">How We Impacts</Link>
                                        <Link className="dropdown-item" to="/how-it-works">How It Works</Link>
                                        <Link className="dropdown-item" to="/world-people-of-nature-day">People of Nature Day</Link>
                                        {this.state.adminAccess && 
                                            <>
                                                <Link className="dropdown-item" to="/admin">Admin Panel</Link>
                                                <Link className="dropdown-item" to="/heatmap-analytics">HeatMap Analytics</Link>
                                            </>
                                        }
                                    </div>
                                </div>
                                <div className="dropdown">
                                    <a href="javascript:void(0)" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Terms of Use</a>
                                    <div className="dropdown-menu fadeIn animated" aria-labelledby="navbarDropdownMenuLink">
                                        <Link className="dropdown-item" to="/UserAgreement">User agreement</Link>
                                        <Link className="dropdown-item" to="/PrivacyPolicy">Privacy Policy</Link>
                                        <Link className="dropdown-item" to="/TermsConditions">Terms & conditions</Link>
                                        <Link className="dropdown-item" to="/CookiePolicy">Cookie Policy</Link>
                                        <Link className="dropdown-item" to="/CommunityGuidelines">Community Guidelines</Link>
                                        <Link className="dropdown-item" to="/refund-policy">Refund Policy</Link>
                                        <Link className="dropdown-item" to="/studio-terms-conditions">Studio Terms & conditions</Link>
                                    </div>
                                </div>
                                <div className="dropdown">
                                    <a href="javascript:void(0)" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Support</a>
                                    <div className="dropdown-menu fadeIn animated" aria-labelledby="navbarDropdownMenuLink">                                        
                                        <Link className="dropdown-item" to="/FaQ">FAQ & Support</Link>
                                        {this.state.loginSuccess ? <Link className="dropdown-item" to="/Feedback">Feedback</Link> : null}                                                                               
                                        {this.state.loginSuccess ? <Link className="dropdown-item" to="/signout">Sign Out</Link> : null}
                                    </div>
                                </div>                                
                            </div>
                        </div>
                    </div>
                </div>
                
                <ProfileCompletionPopup creditUserFlag={this.props.creditUserFlag} onCreditUserPopUp={this.props.onCreditUserPopUp} />
                
                <PostSectionPopUp postPopUpOpenFlag={this.props.postPopUpOpenFlag} nominationData={this.props.nominationData} />             
                    
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        preference: state.preference,
        projectCount:
            state.preference &&
            state.preference.pref.value &&
            state.preference.pref.value.totalOpportunityCount,
        networkCountPref: state.networkCountPref,
        networkCount:
            state.networkCountPref &&
            state.networkCountPref.networkCountResult.value &&
            state.networkCountPref.networkCountResult.value.page &&
            state.networkCountPref.networkCountResult.value.page.totalElements,
        messageCountPref: state.messageCountPref,
        messageCount:
            state.messageCountPref &&
            state.messageCountPref.messageCountResult &&
            state.messageCountPref.messageCountResult.value,

        messageReceivedResponse:
            state.notificationsReducer &&
            state.notificationsReducer.messageReceivedResult &&
            state.notificationsReducer.messageReceivedResult.value,
        yearData: state.WpondReducer?.yearData
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getPref: () => dispatch(setPref()),
        getNetworkCount: () => dispatch(setNetworkCount()),
        getMessageCount: () => dispatch(setMessageCount()),
        setMessageCountOnSuccess:()=>dispatch(setMessageCountOnSuccess()),
        setWpondYearData: (state) => dispatch(setWpondYearData(state))
    };
};

export default memo(connect(mapStateToProps, mapDispatchToProps)(LeftPanel));
